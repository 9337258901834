import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for the Iframe Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  static targets = ['wrapper', 'expandBtn', 'collapseBtn', 'grabBtn', 'calcIframe']
  static values = {
    expandedClasses: String,
    collapsedClasses: String,
  }
  connect () {
    super.connect()
    console.log('connected to iframe')
    // add your code here, if applicable
    // console.log('expandedClasses', this.expandedClassesValue)
    // console.log('collapsedClasses', this.collapsedClassesValue)
  }

  expand(e) {
    if (this.hasCalcIframeTarget) {
      this.calcIframeTarget.className = "w-full h-80vh mt-[-46px]"
    }
    // console.log('e', e.currentTarget.dataset)
    this.expandBtnTarget.classList.add('hidden')
    this.collapseBtnTarget.classList.remove('hidden')
    // this.grabBtnTarget.classList.add('hidden')
    this.wrapperTarget.className = this.expandedClassesValue;
    this.wrapperTarget.style = ""
    // this.wrapperTarget.setAttribute('draggable', false)
  }

  collapse(e) {
    if (this.hasCalcIframeTarget) {
      this.calcIframeTarget.className = "w-full h-full"
    }
    // const { type } = e.currentTarget.dataset;
    this.expandBtnTarget.classList.remove('hidden')
    this.collapseBtnTarget.classList.add('hidden')
    this.wrapperTarget.className = this.collapsedClassesValue;
    this.wrapperTarget.style = ""
    // this.wrapperTarget.setAttribute('draggable', true)
  }

  startDragging(e) {
    // console.log('start dragging', e)
    const wrapper = this.wrapperTarget;
    e.dataTransfer.effectAllowed = 'move';
    this.offsetX = e.clientX - parseInt(window.getComputedStyle(wrapper).left);
    this.offsetY = e.clientY - parseInt(window.getComputedStyle(wrapper).top);
    e.dataTransfer.setData('text/plain', ''); // Required for Firefox
    wrapper.style.opacity = '0.4';
  }

  dragging(e) {
    // console.log('dragover')
    const wrapper = this.wrapperTarget;
    e.preventDefault()
    wrapper.style.left = (e.clientX - this.offsetX) + 'px';
    wrapper.style.top = (e.clientY - this.offsetY) + 'px';
    wrapper.style.opacity = '1';
  }
}
