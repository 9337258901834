import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['wrapper', 'expandBtn', 'collapseBtn', 'grabBtn', 'calcIframe']

  connect () {
    super.connect()
    console.log('connected to math iframe')
    this.renderKatex()
    // if (this.hasWrapperTarget) {
    //   console.log('wrapper', this.wrapperTarget)
    //   console.log('adding dragging event listener to wrapper')
    //   this.wrapperTarget.addEventListener()
    // }
    // else console.log('no wrapper target')
  }

  toggle(e) {
    const el = document.getElementById('iframe')
    console.log('e.currentTarget.dataset', e.currentTarget.dataset)
    const { type } = e.currentTarget.dataset;
    // console.log('this iframeType', this.iframeType)
    // console.log('clicked type', type)
    if (el && !el.children?.length) {
      this.iframeType = type
      this.stimulate('MathIframe#show', e.currentTarget)
    } else if (this.iframeType && this.iframeType !== type) {
      this.iframeType = type
      this.stimulate('MathIframe#show', e.currentTarget)
    } else {
      this.iframeType = null
      this.stimulate('MathIframe#hide')
    }
  }

  // expand(e) {
  //   // console.log('e', e.currentTarget.dataset)
  //   if (this.hasCalcIframeTarget) {
  //     this.calcIframeTarget.className = "w-full h-80vh mt-[-46px]"
  //   }
  //   const { type } = e.currentTarget.dataset;
  //   this.expandBtnTarget.classList.add('hidden')
  //   this.collapseBtnTarget.classList.remove('hidden')
  //   // this.grabBtnTarget.classList.add('hidden')
  //   let wrapperClasses = "fixed top-28 left-16 border border-black shadow-xl bg-white z-999 h-80vh"
  //   wrapperClasses += type == 'calculator' ? ' w-2/3' : ' w-3/4'
  //   this.wrapperTarget.className = wrapperClasses;
  //   this.wrapperTarget.style = ""
  //   // this.wrapperTarget.setAttribute('draggable', false)
  // }

  // collapse(e) {
  //   if (this.hasCalcIframeTarget) {
  //     this.calcIframeTarget.className = "w-full h-full"
  //   }
  //   const { type } = e.currentTarget.dataset;
  //   this.expandBtnTarget.classList.remove('hidden')
  //   this.collapseBtnTarget.classList.add('hidden')
  //   // this.grabBtnTarget.classList.remove('hidden')
  //   let wrapperClasses = "fixed top-28 left-16 border border-black shadow-xl bg-white z-999 h-70vh"
  //   wrapperClasses += type == 'calculator' ? ' w-1/4' : ' w-1/3'
  //   this.wrapperTarget.className = wrapperClasses
  //   this.wrapperTarget.style = ""
  //   // this.wrapperTarget.setAttribute('draggable', true)
  // }

  // startDragging(e) {
  //   // console.log('start dragging', e)
  //   const wrapper = this.wrapperTarget;
  //   e.dataTransfer.effectAllowed = 'move';
  //   this.offsetX = e.clientX - parseInt(window.getComputedStyle(wrapper).left);
  //   this.offsetY = e.clientY - parseInt(window.getComputedStyle(wrapper).top);
  //   e.dataTransfer.setData('text/plain', ''); // Required for Firefox
  //   wrapper.style.opacity = '0.4';
  // }

  // dragging(e) {
  //   // console.log('dragover')
  //   const wrapper = this.wrapperTarget;
  //   e.preventDefault()
  //   wrapper.style.left = (e.clientX - this.offsetX) + 'px';
  //   wrapper.style.top = (e.clientY - this.offsetY) + 'px';
  //   wrapper.style.opacity = '1';
  // }

  renderKatex() {
    console.log('initialize katex in iframe controller')
    renderMathInElement(document.body, {
      // customised options
      // • auto-render specific keys, e.g.:
      delimiters: [
          {left: '$$', right: '$$', display: true},
          {left: '$', right: '$', display: false},
          {left: '\\(', right: '\\)', display: false},
          {left: '\\[', right: '\\]', display: true}
      ],
      // • rendering keys, e.g.:
      throwOnError : false
    });
  }
}
