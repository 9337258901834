import ApplicationController from './application_controller'
import CableReady from 'cable_ready'

export default class extends ApplicationController {
  static targets = [ 'container', 'frqResponse', 'saveBtn', 'savedMsg' ]
  connect () {
    super.connect()
    console.log('connected to responses controller')
    // Cancel using custom eventListener because of event duplication issue that can't be fixed
    // document.addEventListener('submissionRejected', this.confirmSubmit.bind(this))
    this.activateKatex()

  }

  activateKatex() {
    if (this.hasContainerTarget && this.containerTarget.dataset.showKatex === 'true') {
      console.log("in responses_controller, show katex true")
      renderMathInElement(document.body, {
        // customised options
        // • auto-render specific keys, e.g.:
        delimiters: [
            {left: '$$', right: '$$', display: true},
            {left: '$', right: '$', display: false},
            {left: '\\(', right: '\\)', display: false},
            {left: '\\[', right: '\\]', display: true}
        ],
        // • rendering keys, e.g.:
        throwOnError : false
      });
    }
  }

  trySubmit(e) {
    // onClick submit button, check if every questions on this LP have been answered
    const csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    console.log(e.target.dataset)
    const { lessonId } = e.target.dataset
    fetch(`/${lessonId}/try_submit`, {headers: {"X-CSRF-TOKEN": csrf}})
      .then(response => response.json())
      .then(res => {
        if (res.msg === 'ok' && !res.unsubmitted) {
          if (confirm(`Are you sure you want to submit?`)) {
            this.loading();
            this.stimulate('Responses#submit', e.target).then(() => {
              this.loading('hide')
              window.location.href = `/courses/${e.target.dataset.courseSlug}`
            })
          }
        } else {
          if (confirm(`${res.msg} \nSubmit anyway?`)) {
            this.loading();
            this.stimulate('Responses#force_submit', lessonId).then(() => {
              this.loading('hide')
              window.location.href = `/courses/${e.target.dataset.courseSlug}`
            })
          }
        }
      })
      .catch(err => console.log('err', err))
  }

  // confirmSubmit(e) {
  //   console.log(e)
  //   console.log(e.detail)
  //   const { lpId, message } = e.detail

  //   // alert('test')

  //   // if (confirm(`${message} \nSubmit anyway?`)) {
  //   //   this.stimulate('Responses#force_submit', lpId)
  //   // } else {
  //   //   // e.detail.cancel = true
  //   //   // this.stopListeningToSubmissionReject()
  //   // }
  // }

  // stopListeningToSubmissionReject() {
  //   document.removeEventListener('submissionRejected', this.confirmSubmit.bind(this))
  // }

  disconnect () {
    // this.channel.unsubscribe()
   // this.stopListeningToSubmissionReject()
  }

  saveFrq(e) {
    // console.log(e.currentTarget)
    const {lpqId} = e.currentTarget.dataset
    // console.log({lpqId})
    // const input = document.querySelector(`textarea[data-lpq-id="${lpqId}"]`)
    let input = document.getElementById(`frq-textarea-${lpqId}`)
    console.log(input)
    this.stimulate('Responses#save_frq_response', input).then(res => {
      document.getElementById(`frq-save-msg-question-${lpqId}`).innerText = 'Saved!'
      const btn = document.getElementById(`frq-save-btn-question-${lpqId}`)
      btn.disabled = false
      btn.classList.remove('btn-yellow')
      btn.classList.remove('frq-save-wrapper')
      btn.classList.add('btn-disabled')
    })
  }

  enableBtn(e) {
    // console.log(e.target.dataset)
    const {lpqId} = e.target.dataset
    document.getElementById(`frq-save-msg-question-${lpqId}`).innerText = ''
    const btn = document.getElementById(`frq-save-btn-question-${lpqId}`)
    btn.disabled = false
    btn.classList.add('btn-yellow')
    btn.classList.add('frq-save-wrapper')
    btn.classList.remove('btn-disabled')
  }

  afterReflex(element, reflex) {
    this.activateKatex()
  }
}
