// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "dropdown" ]

  connect() {
    // this.outputTarget.textContent = 'Hello, Stimulus!'
  }

  toggleProfile(e) {
    console.log(e)
    console.log('target?', this.dropdownTarget)
  }

  validateMcqTextResponse(e) {
    const { choicesCount } = e.currentTarget.dataset;
    const input = e.currentTarget.value.toUpperCase();
    const validChoices = Array.from({ length: choicesCount }, (_, i) => String.fromCharCode(65 + i)); // Generate letters A, B, C, ..., depending on choicesCount
    console.log('choicesCount', choicesCount);
    console.log('input', input);
    console.log('validChoices', validChoices);

    if (input !== '' && !validChoices.includes(input)) {
      e.currentTarget.setCustomValidity(`Please enter a valid choice: ${validChoices.join(', ')}`);
    } else {
      e.currentTarget.setCustomValidity('');
    }
    e.currentTarget.reportValidity();
    e.currentTarget.value = input;
  }
}
